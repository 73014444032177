<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="8"
      >
        <v-row>
          <!-- Koy qarzy supplier -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-alert
              class="text-center white--text"
              :class="{
                'red lighten-1': !$vuetify.theme.dark,
                'red': $vuetify.theme.dark,
              }"
            >
              <div class="text-h6 mb-3">
                {{ $t('Total Suppliers Loan') }}
              </div>
              <div class="text-h5 font-weight-bold">
                {{ $_format_number(2300) }}
              </div>
            </v-alert>
          </v-col>

          <!-- Koy qarzy customer -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-alert
              class="text-center white--text"
              :class="{
                'blue lighten-1': !$vuetify.theme.dark,
                'blue': $vuetify.theme.dark,
              }"
            >
              <div class="text-h6 mb-3">
                {{ $t('Total Customers Loan') }}
              </div>
              <div class="text-h5 font-weight-bold">
                {{ $_format_number(2300) }}
              </div>
            </v-alert>
          </v-col>

          <!-- 3 zortring jory masraf -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-card
              flat
              class="datatable-card-background datatable-card-border"
            >
              <v-card-text>
                <Doughnut
                  :chart-options="chartOptions"
                  :chart-data="chartData"
                  :chart-id="chartId"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- 3 bashtrin customer -->
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
          >
            <v-alert
              class="text-center white--text"
              :class="{
                'red lighten-1': !$vuetify.theme.dark,
                'red': $vuetify.theme.dark,
              }"
            >
              <div class="text-h6 mb-3">
                {{ $t('Total Suppliers Loan') }}
              </div>
              <div class="text-h5 font-weight-bold">
                {{ $_format_number(2300) }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)


export default {
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.$_section_title({ title: 'Dashboard', icon: 'mdil-view-dashboard' })
  }
}
</script>

<style lang="scss" scoped>
</style>