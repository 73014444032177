var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-alert',{staticClass:"text-center white--text",class:{
              'red lighten-1': !_vm.$vuetify.theme.dark,
              'red': _vm.$vuetify.theme.dark,
            }},[_c('div',{staticClass:"text-h6 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('Total Suppliers Loan'))+" ")]),_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$_format_number(2300))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-alert',{staticClass:"text-center white--text",class:{
              'blue lighten-1': !_vm.$vuetify.theme.dark,
              'blue': _vm.$vuetify.theme.dark,
            }},[_c('div',{staticClass:"text-h6 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('Total Customers Loan'))+" ")]),_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$_format_number(2300))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-card',{staticClass:"datatable-card-background datatable-card-border",attrs:{"flat":""}},[_c('v-card-text',[_c('Doughnut',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-alert',{staticClass:"text-center white--text",class:{
              'red lighten-1': !_vm.$vuetify.theme.dark,
              'red': _vm.$vuetify.theme.dark,
            }},[_c('div',{staticClass:"text-h6 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('Total Suppliers Loan'))+" ")]),_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$_format_number(2300))+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }